import React from "react";
import {
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  FormControl,
  InputLabel,
} from "@mui/material";
import { MeetingHostSchedule } from "../../../../model/meeting_host_schedule";
import { useBoundStore } from "../../../../store/store";

interface HostSelectionProps {
  value: MeetingHostSchedule; // Ensure value is defined
  onChange: (value: MeetingHostSchedule) => void;
}

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const RecurringHostSelection: React.FC<HostSelectionProps> = ({
  value,
  onChange,
}) => {
  const hostsById = useBoundStore((state) => state.hostsById);
  const handleScheduleChange = (
    day: keyof MeetingHostSchedule,
    hostId: string
  ) => {
    const newValue: MeetingHostSchedule = {
      ...value,
      [day]: hostsById[hostId],
    };
    onChange(newValue);
  };

  console.log("value", JSON.stringify(value));

  return (
    <FormControl fullWidth margin="normal">
      <FormControl fullWidth margin="normal">
        <InputLabel id="default-host-label">Select a default host:</InputLabel>
        <Select
          labelId="default-host-label"
          value={value.default?.id ?? ""} // Ensure default value is defined
          onChange={(e) =>
            handleScheduleChange("default", e.target.value as string)
          }
        >
          <MenuItem value="">Select a default host</MenuItem>
          {Object.entries(hostsById).map(([id, host]) => (
            <MenuItem key={id} value={id}>
              {host.displayName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <br />
      <br />
      <div>
        <p>Select hosts for each day:</p>
        <Table>
          <TableBody>
            {[
              "monday",
              "tuesday",
              "wednesday",
              "thursday",
              "friday",
              "saturday",
              "sunday",
            ].map((day) => {
              console.log(
                "daval",
                day,
                value[day as keyof MeetingHostSchedule]
              );
              return (
                <TableRow key={day} data-testid={`${day}-row-test`}>
                  <TableCell>{capitalizeFirstLetter(day)}</TableCell>
                  <TableCell>
                    <FormControl fullWidth disabled={!value.default}>
                      <InputLabel id={`${day}-label`}>Select a host</InputLabel>
                      <Select
                        labelId={`${day}-label`}
                        value={
                          value[day as keyof MeetingHostSchedule]?.id || ""
                        }
                        onChange={(e) =>
                          handleScheduleChange(
                            day as keyof MeetingHostSchedule,
                            e.target.value as string
                          )
                        }
                      >
                        <MenuItem value="" disabled={!value.default}>
                          Select a host
                        </MenuItem>
                        {Object.entries(hostsById).map(([id, host]) => (
                          <MenuItem key={id} value={id}>
                            {host.displayName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </FormControl>
  );
};
