import { RestEndpoint, RestGenerics } from "@rest-hooks/rest/next";
import { Auth } from "aws-amplify";

export default class AuthdEndpoint<
  O extends RestGenerics = any
> extends RestEndpoint<O> {
  async getHeaders(headers: HeadersInit) {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();

    return {
      ...headers,
      Authorization: token,
    };
  }
}
