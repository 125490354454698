import { Entity, schema } from "@rest-hooks/rest";
import AuthdEndpoint from "./AuthdEndpoint";
import { BASE_URL } from "./constants";

export interface UserUpdateValues {
  id: string;
  displayName: string;
  location: string;
}

const sleep = (milliseconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

/////////////// REST API //////////////////////
type NullableBoolean = boolean | null | undefined;

export class UserAdminInfo extends Entity {
  id = "";
  email = "";
  displayName = "";
  createdAtUtc = "";
  accountState = "";
  stripeCustomerId = "";
  notificationsAllow: NullableBoolean = undefined;
  settingSharingEmailsIncludeCalendarInvites: NullableBoolean = undefined;
  settingSharingEmailsSelfWhenNotSharing: NullableBoolean = undefined;
  settingWeeklyEmailsEnabled: NullableBoolean = undefined;
  pushIds: string[] = [];

  pk(
    parent?: any,
    key?: string | undefined,
    args?: readonly any[] | undefined
  ): string | undefined {
    return this.id;
  }
}

export const usersAdminInfoEndpoint = new AuthdEndpoint({
  urlPrefix: BASE_URL,
  path: "/admin/users",
  schema: { users: [UserAdminInfo], nextToken: "" },
  searchParams: {} as { filter?: string },
});

export const usersAdminInfoEndpointPaginated =
  usersAdminInfoEndpoint.paginated("nextToken");

export const sendPushEndpoint = new AuthdEndpoint({
  urlPrefix: BASE_URL,
  path: "/admin/user/:id/send-push",
  method: "POST",
  schema: { messageIds: [] },
});

export const resendUserActivationEndpoint = new AuthdEndpoint({
  urlPrefix: BASE_URL,
  path: "/admin/user/:id/resend-activation",
  method: "POST",
  sideEffect: true,
  schema: { id: "" },
});

export const createUserEndpoint = new AuthdEndpoint({
  urlPrefix: BASE_URL,
  path: "/admin/user",
  method: "POST",
  sideEffect: true,
  schema: UserAdminInfo,
});

export const UserResource = {
  getList: usersAdminInfoEndpoint,
  sendPush: sendPushEndpoint,
  resendActivation: resendUserActivationEndpoint,
  createUser: createUserEndpoint,
};
