import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { ResendActivation } from "./ResendActivation";
import { AsyncBoundary, useController, useSuspense } from "@rest-hooks/react";
import {
  UserAdminInfo,
  usersAdminInfoEndpoint,
  usersAdminInfoEndpointPaginated,
} from "../../api/users";
import { LinearProgress, TextField, Tooltip } from "@mui/material";
import { SendNotification } from "./SendNotification";
import { InfinitePaginationTable } from "./InfinitePaginationTable";
import { set } from "date-fns";
import DelayedInput from "./DelayedInput";
import { fi } from "date-fns/locale";

interface UserListItemProps {
  user: UserAdminInfo;
  index: number;
}

export const UserListItem: React.FC<UserListItemProps> = ({ user, index }) => {
  return (
    <Box
      sx={{
        display: "flex",
        borderBottom: "solid 1px gray",
      }}
    >
      <Box
        sx={{
          flex: "1",
        }}
      >
        <Typography>{index + 1}.</Typography>
      </Box>
      <Tooltip title={`User ID: ${user.id}`}>
        <Box
          sx={{
            flex: "2",
          }}
        >
          <Typography>{user.email}</Typography>
        </Box>
      </Tooltip>
      <Box
        sx={{
          flex: "2",
        }}
      >
        <Typography>{user.displayName}</Typography>
      </Box>
      <Box
        sx={{
          flex: "2",
        }}
      >
        <Typography
          sx={{
            color: user.accountState === "CONFIRMED" ? "green" : "red",
          }}
        >
          {user.accountState}
        </Typography>
      </Box>
      <Box>
        <ResendActivation userEmail={user.email} userId={user.id} />
      </Box>
      <Box
        sx={{
          flex: "2",
        }}
      >
        {!!user.pushIds.length && (
          <SendNotification userId={user.id} userName={user.displayName} />
        )}
      </Box>
    </Box>
  );
};

export const UserListPage = () => {
  const [emailFilter, setEmailFilter] = React.useState<string>();
  const { users, nextToken } = useSuspense(usersAdminInfoEndpoint, {
    filter: emailFilter,
  });
  const ctrl = useController();
  const [loading, setLoading] = React.useState(true);

  const columns = [
    { id: "email", label: "Email" },
    { id: "displayName", label: "Name" },
    {
      id: "accountState",
      label: "State",
      render: (user: UserAdminInfo) => (
        <Typography
          sx={{
            color: user.accountState === "CONFIRMED" ? "green" : "red",
          }}
        >
          {user.accountState}
        </Typography>
      ),
    },
    {
      id: "actions",
      label: "Actions",
      render: (user: UserAdminInfo) => (
        <Box>
          <Box>
            <ResendActivation userEmail={user.email} userId={user.id} />
          </Box>
          <Box
            sx={{
              flex: "2",
            }}
          >
            {!!user.pushIds.length && (
              <SendNotification userId={user.id} userName={user.displayName} />
            )}
          </Box>
        </Box>
      ),
    },
  ];

  const loadNextPage = () => {
    if (nextToken && nextToken.length > 0) {
      setLoading(true);
      ctrl.fetch(usersAdminInfoEndpointPaginated, {
        nextToken,
        filter: emailFilter,
      });
    }
  };

  const handleFilterChange = React.useCallback(
    (filt: string) => {
      setLoading(true);
      setEmailFilter(filt);
      ctrl.fetch(usersAdminInfoEndpoint, { filter: filt });
    },
    [ctrl, emailFilter]
  );

  React.useEffect(() => {
    setLoading(false);
  }, [ctrl, nextToken, emailFilter]);

  users.sort((a, b) => {
    if (a.email < b.email) {
      return -1;
    } else if (a.email > b.email) {
      return 1;
    }
    return 0;
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box>
        <DelayedInput
          label="Email starts with:"
          variant="outlined"
          value={emailFilter}
          onDelayedChange={(txt) => {
            if (txt !== emailFilter) {
              console.log("email filter", txt);
              handleFilterChange(txt);
            }
          }}
          sx={{
            width: "100%",
          }}
        />
      </Box>
      <InfinitePaginationTable
        columns={columns}
        items={users}
        pageSize={10}
        loading={loading}
        onLoadNext={loadNextPage}
      />
    </Box>
  );
};

export const UserList = () => {
  return (
    <AsyncBoundary>
      <UserListPage />
    </AsyncBoundary>
  );
};
