import React from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import { ApiRequests } from "../components/api_requests/ApiRequests";
import { Management as CalendarManagement } from "../components/calendar/Management";
import { Management as UserManagement } from "../components/users/Management";
import {
  FocusedAppBar,
  ScreenInfo,
} from "../components/controls/FocusedAppBar";

const screens: ScreenInfo[] = [
  {
    id: "userManagement",
    name: "User Management",
  },
  {
    id: "calendarManagement",
    name: "Calendar Management",
  },
  {
    id: "apiRequests",
    name: "API Requests",
  },
];

const screenComponents: Record<string, () => JSX.Element> = {
  userManagement: () => <UserManagement />,
  calendarManagement: () => <CalendarManagement />,
  apiRequests: () => <ApiRequests />,
};

export const Home = () => {
  const [activeScreenId, setActiveScreenId] = React.useState("userManagement");

  return (
    <Box style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <FocusedAppBar
        screens={screens}
        onSetActiveScreen={(screenId) => {
          setActiveScreenId(screenId);
        }}
      />
      <Container
        maxWidth="xl"
        style={{ flex: 1, backgroundColor: "ghostwhite" }}
      >
        {React.createElement(screenComponents[activeScreenId])}
      </Container>
    </Box>
  );
};
