import { Stack, Typography } from "@mui/material";

interface DetailFieldProps {
  field: string;
  value?: string | boolean | string[] | null;
}

export const DetailField = ({ field, value }: DetailFieldProps) => {
  return (
    <Stack direction="row" gap={2} key={field}>
      <Typography variant="body1" fontWeight="bold">
        {field.toString()}:
      </Typography>
      <Typography variant="body1" sx={{ ...(!value && { color: "red" }) }}>
        {value ? value?.toString() : "Not found"}
      </Typography>
    </Stack>
  );
};
