import { Host as ApiHost } from "../api/hosts";

export interface Host {
  id: string;
  displayName: string;
  email: string;
  createdAtUtc: string;
  updatedAt: string;
  accountState: string;
}

export const fromApiHost = (apiHost: ApiHost): Host => {
  return {
    id: apiHost.id,
    displayName: apiHost.displayName,
    email: apiHost.email,
    createdAtUtc: apiHost.createdAtUtc,
    updatedAt: apiHost.updatedAt,
    accountState: apiHost.accountState,
  };
};
