import React from "react";
import "./App.css";

import { AuthenticatedUserProvider } from "./providers/AuthenticatedUserProvider";
import { ApolloCognitoProvider } from "./providers/ApolloCognitoProvider";

import { Main } from "./Main";

import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@rest-hooks/react";

Amplify.configure(awsExports);

const theme = createTheme({});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthenticatedUserProvider>
        <ApolloCognitoProvider>
          <CacheProvider>
            <Main />
          </CacheProvider>
        </ApolloCognitoProvider>
      </AuthenticatedUserProvider>
    </ThemeProvider>
  );
}

export default App;
