import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  InputAdornment,
  Stack,
  TextField,
  Button,
} from "@mui/material";
import { MeetingTypes } from "../helpers";
import { ReactNode } from "react";
import SearchOutlined from "@mui/icons-material/SearchOutlined";
import CloseOutlined from "@mui/icons-material/CloseOutlined";

export interface FilterTypes {
  meetingType?: string;
  search?: string;
  status?: string;
  hostId?: string;
}

export const defaultFilters: FilterTypes = {
  meetingType: "All",
  search: "",
  status: "All",
  hostId: "All",
};

export interface HostWithId {
  id: string;
  name: string;
}

interface FilterProps {
  filters: FilterTypes;
  hosts: HostWithId[];
  onMeetingTypeChange: (
    event: SelectChangeEvent<string>,
    child: ReactNode
  ) => void;
  onStatusChange: (event: SelectChangeEvent<string>, child: ReactNode) => void;
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onHostChange: (event: SelectChangeEvent<string>, child: ReactNode) => void;
  hasFilters: boolean;
  onClearFilters: () => void;
}

export const Filters: React.FC<FilterProps> = ({
  filters,
  hosts,
  onMeetingTypeChange,
  onStatusChange,
  onSearchChange,
  onHostChange,
  hasFilters,
  onClearFilters,
}) => {
  let options = [
    MeetingTypes.community_session,
    MeetingTypes.custom,
    MeetingTypes.new_member_orientation,
    MeetingTypes.night_owl,
    MeetingTypes.office_hours,
    MeetingTypes.power_hour,
    MeetingTypes.wakeup_call,
    MeetingTypes.weekly_planning,
  ];

  const { meetingType, search, status, hostId } = filters || {};
  return (
    <Stack direction={"row"} gap={3} margin="0 10px">
      <TextField
        key="search"
        value={search}
        onChange={onSearchChange}
        placeholder="Search meetings"
        size="small"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchOutlined />
            </InputAdornment>
          ),
        }}
      />
      <FormControl size="small" key="status">
        <InputLabel>Status</InputLabel>
        <Select
          value={status}
          onChange={onStatusChange}
          sx={{ minWidth: "150px" }}
          label="Status"
        >
          <MenuItem value="All" key="all">
            All
          </MenuItem>
          <MenuItem value="Missing data" key="missingData">
            Missing data
          </MenuItem>
          <MenuItem value="NotVisible" key="notVisible">
            Not visible
          </MenuItem>
        </Select>
      </FormControl>

      <FormControl size="small" key="meetingType">
        <InputLabel>Meeting type</InputLabel>
        <Select
          value={meetingType}
          onChange={onMeetingTypeChange}
          sx={{ minWidth: "150px" }}
          label="Meeting type"
        >
          <MenuItem value="All">All</MenuItem>
          {options.map((o) => (
            <MenuItem value={o} key={o}>
              {o}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl size="small" key="host">
        <InputLabel>Host</InputLabel>
        <Select
          value={hostId ?? "All"}
          onChange={onHostChange}
          sx={{ minWidth: "150px" }}
          label="Host"
        >
          <MenuItem value="All" key="all">
            All
          </MenuItem>
          {hosts.map((h) => (
            <MenuItem value={h.id} key={h.id}>
              {h.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {hasFilters && (
        <Button onClick={onClearFilters} startIcon={<CloseOutlined />}>
          Clear all filters
        </Button>
      )}
    </Stack>
  );
};
