import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Box,
  CircularProgress,
} from "@mui/material";

// Type for a generic table item
interface TableItem {
  id: string | number;
  [key: string]: any;
}

interface InfinitePaginationTableProps<T extends TableItem> {
  columns: {
    id: string;
    label: string;
    render?: (item: T) => React.ReactNode;
  }[];
  items: T[];
  pageSize?: number;
  loading?: boolean;
  onLoadNext: () => void;
}

export function InfinitePaginationTable<T extends TableItem>({
  columns,
  items,
  pageSize = 10,
  loading = false,
  onLoadNext,
}: InfinitePaginationTableProps<T>) {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(items.length / pageSize);
  const showNextPage = items.length >= currentPage * pageSize;

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
    if (page === totalPages + 1) {
      onLoadNext();
    }
  };

  const getCurrentPageItems = () => {
    const start = (currentPage - 1) * pageSize;
    const end = start + pageSize;
    return items.slice(start, end);
  };

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {getCurrentPageItems().map((item) => (
              <TableRow key={item.id}>
                {columns.map((column) => (
                  <TableCell key={`${item.id}-${column.id}`}>
                    {column.render ? column.render(item) : item[column.id]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            {loading && (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  <CircularProgress size={24} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Pagination
          count={totalPages + (showNextPage ? 1 : 0)}
          page={currentPage}
          onChange={handlePageChange}
          disabled={loading}
        />
      </Box>
    </Box>
  );
}
