import { Entity, schema } from "@rest-hooks/rest";
import AuthdEndpoint from "./AuthdEndpoint";
import { BASE_URL } from "./constants";

export interface HostSchedule {
  monday?: string;
  tuesday?: string;
  wednesday?: string;
  thursday?: string;
  friday?: string;
  saturday?: string;
  sunday?: string;
  default: string;
}

export interface InstanceMetadata {
  meetingType?: string;
  memberVisible?: boolean;
  hostId?: string;
}

export interface RecurringMetadata {
  meetingType?: string;
  memberVisible?: boolean;
  hostSchedule?: HostSchedule;
}

export class RecurringMeeting extends Entity {
  id: string = "";
  recurrenceRule?: string[] | null = [];
  metadata: RecurringMetadata = {};

  pk(
    parent?: any,
    key?: string | undefined,
    args?: readonly any[] | undefined
  ): string | undefined {
    return this.id;
  }
}

export class Meeting extends Entity {
  id: string = "";
  recurringMeetingId?: string | null = "";
  recurrenceRule?: string[] | null = [];
  title: string = "";
  start: string = "";
  end: string = "";
  description: string = "";
  meetingUrl: string = "";
  hostId?: string | null = "";
  metadata: InstanceMetadata = {};
  recurringMeeting?: RecurringMeeting | null = null;

  pk(
    parent?: any,
    key?: string | undefined,
    args?: readonly any[] | undefined
  ): string | undefined {
    return this.id;
  }
}

const getMeetingsEndpoint = new AuthdEndpoint({
  urlPrefix: BASE_URL,
  path: "/admin/meetings",
  searchParams: {
    start: "",
    end: "",
  },
  schema: new schema.Collection([Meeting]),
  process: async (result) => {
    return result as Meeting[];
  },
});

class UpdateResult extends Entity {
  result = "";

  pk() {
    return this.result;
  }
}

const updateMeetingMetadata = new AuthdEndpoint({
  urlPrefix: BASE_URL,
  path: "/admin/meeting/:id",
  method: "PATCH",
  sideEffect: true,
  schema: UpdateResult,
  process: async (result) => {
    return result as Meeting;
  },
});

export const MeetingResource = {
  getList: getMeetingsEndpoint,
  update: updateMeetingMetadata,
};
