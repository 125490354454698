import React, { useState } from "react";
import axios from "axios";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { BASE_URL } from "../../api/constants";
import { Auth } from "aws-amplify";
import { set } from "date-fns";

export const ApiRequests = () => {
  const [apiPath, setApiPath] = useState("");
  const [requestBody, setRequestBody] = useState("");
  const [requestBodyError, setRequestBodyError] = useState<string>(); // TODO: Use this to show error message [optional
  const [httpMethod, setHttpMethod] = useState("GET"); // Default to GET
  const [response, setResponse] = useState("");

  const makeApiCall = async () => {
    try {
      let response;

      setResponse("Loading...");

      const accessToken = (await Auth.currentSession())
        .getIdToken()
        .getJwtToken();

      if (httpMethod === "GET") {
        response = await axios.get(`${BASE_URL}${apiPath}`, {
          headers: {
            Authorization: accessToken,
          },
        });
      } else if (httpMethod === "POST") {
        response = await axios.post(
          `${BASE_URL}${apiPath}`,
          JSON.parse(requestBody),
          {
            headers: {
              Authorization: accessToken,
              "Content-Type": "application/json",
            },
          }
        );
      } else if (httpMethod === "PUT") {
        response = await axios.put(
          `${BASE_URL}${apiPath}`,
          JSON.parse(requestBody),
          {
            headers: {
              Authorization: accessToken,
              "Content-Type": "application/json",
            },
          }
        );
      } else if (httpMethod === "DELETE") {
        response = await axios.delete(`${BASE_URL}${apiPath}`, {
          headers: {
            Authorization: accessToken,
          },
        });
      }

      setResponse(JSON.stringify(response?.data, null, 2));
    } catch (error) {
      console.error(error);
      setResponse(JSON.stringify(error, null, 2));
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        AWS Cognito Authenticated API Caller
      </Typography>
      <FormControl fullWidth variant="outlined" margin="normal">
        <InputLabel>HTTP Method</InputLabel>
        <Select
          label="HTTP Method"
          value={httpMethod}
          onChange={(e) => {
            setHttpMethod(e.target.value);
            if (e.target.value === "GET" || e.target.value === "DELETE") {
              setRequestBody("");
            }
          }}
        >
          <MenuItem value="GET">GET</MenuItem>
          <MenuItem value="POST">POST</MenuItem>
          <MenuItem value="PUT">PUT</MenuItem>
          <MenuItem value="DELETE">DELETE</MenuItem>
        </Select>
      </FormControl>
      <TextField
        fullWidth
        label="API Path"
        variant="outlined"
        value={apiPath}
        onChange={(e) => setApiPath(e.target.value)}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Request Body"
        variant="outlined"
        disabled={httpMethod === "GET" || httpMethod === "DELETE"}
        multiline
        rows={4}
        value={requestBody}
        onChange={(e) => {
          setRequestBody(e.target.value);
          if (e.target.value !== "") {
            try {
              JSON.parse(e.target.value);
              setRequestBodyError(undefined);
            } catch (error: any) {
              setRequestBodyError(
                `Request body must be valid JSON if set: error.message`
              );
            }
          } else {
            setRequestBodyError(undefined);
          }
        }}
        error={requestBodyError ? true : false}
        margin="normal"
      />
      <Button
        variant="contained"
        color="primary"
        onClick={makeApiCall}
        disabled={!apiPath}
      >
        Make API Call
      </Button>
      <Box mt={2}>
        <Typography variant="h6">API Response:</Typography>
        <pre>{response}</pre>
      </Box>
    </Container>
  );
};
