import { Meeting as ApiMeeting } from "../api/meetings";
import { Host } from "./host";
import {
  MeetingHostSchedule,
  fromApiHostSchedule,
} from "./meeting_host_schedule";

export interface InstanceMetadata {
  meetingType?: string;
  host?: Host;
  memberVisible: boolean;
}

export interface RecurringMetadata {
  meetingType?: string;
  memberVisible?: boolean;
  hostSchedule?: MeetingHostSchedule;
}

export const instanceFromApiMeeting = (
  apiMeeting: ApiMeeting,
  hostsById: Record<string, Host>
): InstanceMetadata => {
  return {
    meetingType: apiMeeting.metadata.meetingType,
    host: apiMeeting.metadata.hostId
      ? hostsById[apiMeeting.metadata.hostId]
      : undefined,
    memberVisible: apiMeeting.metadata.memberVisible ?? false,
  };
};

export const recurringFromApiMeeting = (
  apiMeeting: ApiMeeting,
  hostsById: Record<string, Host>
): RecurringMetadata | undefined => {
  if (!apiMeeting.recurringMeeting) {
    return undefined;
  }
  const apiMetadata = apiMeeting.recurringMeeting.metadata;
  return {
    meetingType: apiMetadata.meetingType,
    hostSchedule: fromApiHostSchedule(apiMetadata.hostSchedule, hostsById),
    memberVisible: apiMetadata.memberVisible ?? false,
  };
};
