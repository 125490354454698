import { StateCreator } from "zustand";
import { MeetingResource } from "../api/meetings";
import { InstanceMetadata, RecurringMetadata } from "../model/meeting_metadata";
import { Meeting, fromApiMeeting } from "../model/meeting";
import { Host, fromApiHost } from "../model/host";
import { HostResource } from "../api/hosts";
import { toMeetingHostIdSchedule } from "../model/meeting_host_schedule";

export interface MeetingsSlice {
  meetings: Meeting[];
  hostsById: Record<string, Host>;
  start: Date;
  end: Date;
  load: (start: Date, end: Date) => void;
  setInstanceMetadata: (meetingId: string, metadata: InstanceMetadata) => void;
  setRecurringMetadata: (
    meetingId: string,
    metadata: RecurringMetadata
  ) => void;
}

export const createMeetingsSlice: StateCreator<
  MeetingsSlice,
  [],
  [],
  MeetingsSlice
> = (set, get) => ({
  meetings: [],
  hostsById: {},
  start: new Date(),
  end: new Date(),
  load: async (start: Date, end: Date) => {
    // ... load meetings
    const apiMeetingsPromise = MeetingResource.getList({
      start: start.toISOString(),
      end: end.toISOString(),
    });
    const apiHostsPromise = HostResource.getList();
    const [apiMeetings, apiHosts] = await Promise.all([
      apiMeetingsPromise,
      apiHostsPromise,
    ]);
    const hosts = apiHosts.users.map(fromApiHost);
    const hostsById = hosts.reduce((acc, host) => {
      acc[host.id] = host;
      return acc;
    }, {} as Record<string, Host>);
    const meetings = apiMeetings.map((m) => fromApiMeeting(m, hostsById));
    meetings.sort((a, b) => a.start.getTime() - b.start.getTime());
    // we'll completely replace the meetings when
    // loading them from the server
    console.log("Loaded meetings", meetings);
    set((_) => ({
      meetings,
      hostsById,
      start,
      end,
    }));
  },
  setInstanceMetadata: async (
    meetingId: string,
    metadata: InstanceMetadata
  ) => {
    // ... update meeting metadata
    const updatedMeeting = await MeetingResource.update(
      { id: meetingId },
      {
        instanceMetadata: {
          meetingType: metadata.meetingType,
          memberVisible: metadata.memberVisible,
          hostId: metadata.host ? metadata.host.id : undefined,
        },
      }
    );
    const current = get();
    await current.load(current.start, current.end);
  },
  setRecurringMetadata: async (
    meetingId: string,
    metadata: RecurringMetadata
  ) => {
    // ... update meeting metadata
    const updatedMeeting = await MeetingResource.update(
      { id: meetingId },
      {
        recurringMetadata: {
          meetingType: metadata.meetingType,
          memberVisible: metadata.memberVisible,
          hostSchedule: metadata.hostSchedule
            ? toMeetingHostIdSchedule(metadata.hostSchedule)
            : undefined,
        },
      }
    );
    const current = get();
    await current.load(current.start, current.end);
  },
});
