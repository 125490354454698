import React, { useState, useCallback, useEffect } from "react";
import { TextField, TextFieldProps } from "@mui/material";

interface DelayedInputProps extends Omit<TextFieldProps, "onChange"> {
  onDelayedChange: (value: string) => void;
  delay?: number;
  value?: string;
}

const DelayedInput: React.FC<DelayedInputProps> = ({
  onDelayedChange,
  delay = 500,
  value: initialValue = "",
  ...textFieldProps
}) => {
  const [value, setValue] = useState(initialValue);
  const [debouncedValue, setDebouncedValue] = useState(value);

  // Update internal value immediately for responsive UI
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    },
    []
  );

  // Set up debounced value
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  // Call the callback when debounced value changes
  useEffect(() => {
    onDelayedChange(debouncedValue);
  }, [debouncedValue, onDelayedChange]);

  // Sync with external value changes
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <TextField {...textFieldProps} value={value} onChange={handleChange} />
  );
};

export default DelayedInput;
