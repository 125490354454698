import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ReplayIcon from "@mui/icons-material/Replay";

import { Color } from "../../../../styles/SharedStyles";
import { DetailField } from "./DetailField";
import { BadgeType, MeetingInfoBadge } from "../MeetingInfoBadge";
import React from "react";
import { RecurringMetadataEditDialog } from "../metadata_editing/RecurringMetadataEditDialog";
import {
  getTimeString,
  meetingTypeToString,
  recurrenceRuleString,
} from "../../helpers";
import { Meeting } from "../../../../model/meeting";
import { toDisplayString } from "../../../../model/meeting_host_schedule";
import { useBoundStore } from "../../../../store/store";

interface RecurrenceDetailsViewProps {
  meeting: Meeting;
}

export const RecurrenceDetailsView: React.FC<RecurrenceDetailsViewProps> = ({
  meeting,
}) => {
  if (!meeting.recurringMeeting) {
    throw new Error("Meeting is not recurring");
  }

  const setMeetingMetadata = useBoundStore(
    (state) => state.setRecurringMetadata
  );
  const [isEditing, setIsEditing] = React.useState(false);
  const [showRecurring, setShowRecurring] = React.useState(false);

  const recMeeting = meeting.recurringMeeting!;
  const isVisible = recMeeting.metadata.memberVisible ?? false;
  const meetingType = recMeeting.metadata.meetingType
    ? meetingTypeToString(recMeeting.metadata.meetingType)
    : undefined;
  const isMissingData = !meetingType || !recMeeting.metadata.hostSchedule;

  return (
    <Accordion
      expanded={showRecurring}
      onChange={() => setShowRecurring(!showRecurring)}
      sx={{ bgcolor: Color.LightestPurple, borderRadius: "10px" }}
    >
      <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
        <Stack direction="row" gap={2} alignItems={"center"}>
          <ReplayIcon />
          <Typography variant="body2" fontWeight="bold">
            This is an instance of a recurring meeting.
          </Typography>
          <Typography variant="caption" sx={{ marginLeft: "auto" }}>
            {showRecurring ? "Hide" : "Show"} recurrence details
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack gap={1} padding="20px 60px">
          <Stack gap={1} flex={1} alignItems="flex-end">
            {!isVisible && <MeetingInfoBadge type={BadgeType.notVisible} />}
            {isMissingData && <MeetingInfoBadge type={BadgeType.missingData} />}
          </Stack>
          <Typography fontWeight="bold">Recurrence Details</Typography>
          <Divider />
          <Stack gap={1}>
            <Stack
              gap={2}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h5" fontWeight="bold">
                {meeting.title}
              </Typography>
              <Button
                variant="text"
                onClick={() => setIsEditing(!isEditing)}
                sx={{ marginTop: "10px" }}
              >
                Edit Recurrence
              </Button>
            </Stack>
            <DetailField field="id" value={recMeeting.id} />
            <DetailField
              field="recurrenceRule"
              value={recurrenceRuleString(recMeeting.recurrenceRule)}
            />
            <DetailField
              field="time"
              value={getTimeString(meeting.start, meeting.end)}
            />
            <DetailField
              field="hostName"
              value={toDisplayString(recMeeting.metadata.hostSchedule)}
            />
            <DetailField field="meetingType" value={meetingType} />
          </Stack>
          <RecurringMetadataEditDialog
            key={meeting.id}
            meeting={meeting}
            open={isEditing}
            onCancel={() => setIsEditing(false)}
          />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
