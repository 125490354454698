import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useBoundStore } from "../../../../store/store";
import { Host } from "../../../../model/host";

interface InstanceHostSelectionProps {
  value?: Host;
  onChange: (value: Host) => void;
}

export const InstanceHostSelection: React.FC<InstanceHostSelectionProps> = ({
  value,
  onChange,
}) => {
  const hostsById = useBoundStore((state) => state.hostsById);

  return (
    <FormControl fullWidth margin="normal">
      <InputLabel id="host-select-label">Select a host:</InputLabel>
      <Select
        labelId="host-select-label"
        value={value?.id ?? ""}
        onChange={(e) => {
          const host = hostsById[e.target.value as string];
          onChange(host);
        }}
      >
        <MenuItem key="undef" value={undefined} selected={value === undefined}>
          Unset
        </MenuItem>
        {Object.entries(hostsById).map(([id, host]) => {
          return (
            <MenuItem
              key={host.id}
              value={host.id}
              selected={value?.id === host.id}
            >
              {host.displayName}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
