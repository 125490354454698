export enum Color {
  Purple = "#7B61FF",
  LightPurple = "#CABCEE",
  LightestPurple = "#EFECFF",
  DarkPurple = "#5D2AC3",
  Green = "#D1FF1E",
  DarkGreen = "#02AF0E",
  DarkGray = "#44434C",
  Gray = "#6C6B71",
  LightGray = "#969BA8",
  LightestGray = "#E8E8E8",
  White = "#FFFFFF",
  Blue = "#64B7E5",
  Red = "#F44336",
}

export const boxShadowTop = `1px -2px 8px -5px rgba(0,0,0,0.2)`;
