import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { InstanceHostSelection } from "../host_selection/Instance";
import { Meeting } from "../../../../model/meeting";
import { useBoundStore } from "../../../../store/store";
import { Host } from "../../../../model/host";

interface Props {
  meeting: Meeting;
  open: boolean;
  onCancel: () => void;
}

export const InstanceMetadataEditDialog: React.FC<Props> = ({
  meeting: originalMeeting,
  open,
  onCancel,
}) => {
  const setMeetingMetadata = useBoundStore(
    (state) => state.setInstanceMetadata
  );
  const [currentMeeting, setCurrentMeeting] = useState(originalMeeting);

  const handleSave = React.useCallback(async () => {
    await setMeetingMetadata(currentMeeting.id, currentMeeting.metadata);
    onCancel();
  }, [currentMeeting]);

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onCancel}>
      <DialogTitle>
        <Stack gap={0.5}>
          {"Editing "}
          {currentMeeting.title}{" "}
          <Typography variant="caption">
            **You are making changes to a single instance of a recurring
            meeting. Changes will not affect other instances of the same
            recurrence.
          </Typography>
        </Stack>
      </DialogTitle>

      <DialogContent>
        <InstanceHostSelection
          value={currentMeeting.metadata.host}
          onChange={(value: Host) => {
            setCurrentMeeting({
              ...currentMeeting,
              metadata: {
                ...currentMeeting.metadata,
                host: value,
              },
            });
          }}
        />

        <FormControl fullWidth margin="normal">
          <InputLabel>Meeting Type</InputLabel>
          <Select
            label="Meeting Type"
            value={currentMeeting.metadata.meetingType}
            onChange={(e) =>
              setCurrentMeeting({
                ...currentMeeting,
                metadata: {
                  ...currentMeeting.metadata,
                  meetingType: e.target.value as string,
                },
              })
            }
          >
            {/* Add options for meeting types */}
            <MenuItem value="community_session">Community Session</MenuItem>
            <MenuItem value="custom">Custom</MenuItem>
            <MenuItem value="new_member_orientation">
              New Member Orientation
            </MenuItem>
            <MenuItem value="night_owl">Night Owl</MenuItem>
            <MenuItem value="power_hour">Power Hour</MenuItem>
            <MenuItem value="wakeup_call">Wakeup Call</MenuItem>
            <MenuItem value="weekly_planning">Weekly Planning</MenuItem>
            <MenuItem value="monthly_planning">Monthly Planning</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <FormControlLabel
            control={
              <Switch
                checked={currentMeeting.metadata.memberVisible ?? false}
                onChange={() =>
                  setCurrentMeeting({
                    ...currentMeeting,
                    metadata: {
                      ...currentMeeting.metadata,
                      memberVisible: !currentMeeting.metadata.memberVisible,
                    },
                  })
                }
              />
            }
            label="Visible to Members"
          />
          <FormHelperText>
            When checked, members are able to see and subscribe to this meeting
          </FormHelperText>
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
