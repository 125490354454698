import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useAuthenticatedUserState } from '../providers/AuthenticatedUserProvider';

export const NonHost = () => {
    const { signOut } = useAuthenticatedUserState();

    return <Box>
        <Typography>
            You must be a host or an admin to use this site.
        </Typography>
        <Button
            onClick={signOut}>
            Sign Out
        </Button>
    </Box>;
}