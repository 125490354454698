import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import LockResetIcon from "@mui/icons-material/LockReset";
import Dialog from "@mui/material/Dialog";

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import { UserResource } from "../../api/users";

interface ResendActivationFormProperties {
  userId: string;
  userEmail: string;
  onClose: () => void;
  open: boolean;
}

const ResendActivationForm: React.FC<ResendActivationFormProperties> = ({
  userId,
  userEmail,
  onClose,
  open,
}) => {
  const onResend = async () => {
    console.log("Resending activation to user: ", userEmail, userId);
    try {
      await UserResource.resendActivation({
        id: userId,
      }, {});
    } catch (e) {
      console.error("Error resending activation: ", e);
      console.log(e);
    }
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Send a new activation link?</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Typography variant="body1">
          This will invalidate the previous activation link and send a new one
          to the user.
        </Typography>
        <Typography variant="body1">User: {userEmail}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onResend}>Resend activation</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

interface ResendActivationProperties {
  userId: string;
  userEmail: string;
}

export const ResendActivation: React.FC<ResendActivationProperties> = ({
  userId,
  userEmail,
}) => {
  const [formVisible, setFormVisible] = React.useState(false);

  return (
    <Tooltip title="Resend activation email">
      <Box>
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            setFormVisible(true);
          }}
        >
          <LockResetIcon />
        </IconButton>

        <ResendActivationForm
          open={formVisible}
          userId={userId}
          userEmail={userEmail}
          onClose={() => setFormVisible(false)}
        />
      </Box>
    </Tooltip>
  );
};
