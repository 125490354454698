import { Entity, schema } from "@rest-hooks/rest";
import AuthdEndpoint from "./AuthdEndpoint";
import { BASE_URL } from "./constants";

export class Host extends Entity {
  id: string = "";
  displayName: string = "";
  email: string = "";
  createdAtUtc: string = "";
  updatedAt: string = "";
  accountState: string = "";

  pk(
    parent?: any,
    key?: string | undefined,
    args?: readonly any[] | undefined
  ): string | undefined {
    return this.id;
  }
}

const getHostsEndpoint = new AuthdEndpoint({
  urlPrefix: BASE_URL,
  path: "/admin/hosts",
  schema: new schema.Object({ users: [Host], nextToken: "" }),
  process: async (result) => {
    return result as { users: Host[]; nextToken: string | undefined };
  },
});

export const HostResource = {
  getList: getHostsEndpoint,
};
