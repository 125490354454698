import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { UserResource } from "../../api/users";

interface SendPushRequestBody {
  title: string;
  body: string;
}

interface SendNotificationFormProps {
  userId: string;
  userName: string;
  onClose: () => void;
}

const SendNotificationForm: React.FC<SendNotificationFormProps> = ({
  userId,
  userName,
  onClose,
}) => {
  const [response, setResponse] = React.useState<string>("");
  const [title, setTitle] = React.useState("");
  const [body, setBody] = React.useState("");

  const onSend = async () => {
    const pushBody: SendPushRequestBody = { title, body };

    setResponse("Sending notification...");
    try {
      await UserResource.sendPush({ id: userId }, pushBody);
      onClose();
    } catch (e) {
      console.error("Error sending notification: ", e);
      setResponse("Error sending notification: " + e);
    }
  };
  return (
    <DialogContent
      sx={{
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Typography variant="h5">Send notification to {userName}</Typography>
      <TextField
        label="Title"
        required
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <TextField
        label="Body"
        required
        value={body}
        multiline
        onChange={(e) => setBody(e.target.value)}
      />
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onSend}>Send Notification</Button>
      </DialogActions>
      <Box sx={{ overflowY: "auto", height: "auto" }}>
        <Typography>{response}</Typography>
      </Box>
    </DialogContent>
  );
};

interface SendNotificationProps {
  userId: string;
  userName: string;
}

export const SendNotification: React.FC<SendNotificationProps> = ({
  userId,
  userName,
}) => {
  const [formVisible, setFormVisible] = React.useState(false);
  return (
    <Tooltip title="Send notification">
      <Box>
        <Button
          onClick={(e) => {
            e.preventDefault();
            setFormVisible(true);
          }}
        >
          Send Notification
        </Button>
        <Dialog fullWidth open={formVisible}>
          <SendNotificationForm
            userId={userId}
            userName={userName}
            onClose={() => setFormVisible(false)}
          />
        </Dialog>
      </Box>
    </Tooltip>
  );
};
