import React from "react";

import Box from "@mui/material/Box";
import { MeetingListView } from "./MeetingListView";

export const Management = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flex: 1,
        height: "85vh",
      }}
    >
      <MeetingListView />
    </Box>
  );
};
