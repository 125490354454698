import { HostSchedule } from "../api/meetings";
import { Host } from "./host";

export interface MeetingHostSchedule extends Record<string, Host | undefined> {
  monday?: Host;
  tuesday?: Host;
  wednesday?: Host;
  thursday?: Host;
  friday?: Host;
  saturday?: Host;
  sunday?: Host;
  default?: Host;
}

export interface MeetingHostIdSchedule
  extends Record<string, string | undefined> {
  monday?: string;
  tuesday?: string;
  wednesday?: string;
  thursday?: string;
  friday?: string;
  saturday?: string;
  sunday?: string;
  default?: string;
}

export const fromApiHostSchedule = (
  apiHostSchedule: HostSchedule | undefined,
  hostsById: Record<string, Host>
): MeetingHostSchedule | undefined => {
  if (!apiHostSchedule) {
    return undefined;
  }
  return {
    default: hostsById[apiHostSchedule.default],
    monday: apiHostSchedule.monday
      ? hostsById[apiHostSchedule.monday]
      : undefined,
    tuesday: apiHostSchedule.tuesday
      ? hostsById[apiHostSchedule.tuesday]
      : undefined,
    wednesday: apiHostSchedule.wednesday
      ? hostsById[apiHostSchedule.wednesday]
      : undefined,
    thursday: apiHostSchedule.thursday
      ? hostsById[apiHostSchedule.thursday]
      : undefined,
    friday: apiHostSchedule.friday
      ? hostsById[apiHostSchedule.friday]
      : undefined,
    saturday: apiHostSchedule.saturday
      ? hostsById[apiHostSchedule.saturday]
      : undefined,
    sunday: apiHostSchedule.sunday
      ? hostsById[apiHostSchedule.sunday]
      : undefined,
  };
};

export const fromHostIdString = (
  hostId: string,
  hostsById: Record<string, Host>
): MeetingHostSchedule | undefined => {
  const dailyIds = dailyHostIdsFromString(hostId);
  if (dailyIds) {
    return {
      default: hostsById[dailyIds.default!],
      monday: dailyIds.monday ? hostsById[dailyIds.monday] : undefined,
      tuesday: dailyIds.tuesday ? hostsById[dailyIds.tuesday] : undefined,
      wednesday: dailyIds.wednesday ? hostsById[dailyIds.wednesday] : undefined,
      thursday: dailyIds.thursday ? hostsById[dailyIds.thursday] : undefined,
      friday: dailyIds.friday ? hostsById[dailyIds.friday] : undefined,
      saturday: dailyIds.saturday ? hostsById[dailyIds.saturday] : undefined,
      sunday: dailyIds.sunday ? hostsById[dailyIds.sunday] : undefined,
    };
  }
  return undefined;
};

const dailyHostIdsFromString = (
  idString: string
): MeetingHostIdSchedule | undefined => {
  try {
    const ids = JSON.parse(idString);
    if (ids && typeof ids === "object" && ids.default) {
      return ids;
    }
  } catch (e) {}

  // If the string is not a JSON object, assume it's the default host ID
  return {
    default: idString,
  };
};

const days = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export const toDisplayString = (hosts?: MeetingHostSchedule) => {
  if (!hosts) {
    return "(no host set)";
  }
  if (!hosts.default) {
    throw new Error("Default host must be set");
  }
  const overrides = days
    .filter((day) => hosts[day])
    .map((day) => {
      const host = hosts[day];
      if (!host) {
        throw new Error("Host must be set");
      }
      return `${day.slice(0, 2).toLocaleUpperCase()}: ${host.displayName}`;
    })
    .join(", ");
  const overrideStr = overrides.length > 0 ? ` (${overrides})` : "";
  return `${hosts.default.displayName}${overrideStr}`;
};

export const toMeetingHostIdSchedule = (
  hosts: MeetingHostSchedule
): MeetingHostIdSchedule => {
  const dailyIds: MeetingHostIdSchedule = {};
  for (const day of days) {
    if (hosts[day]) {
      dailyIds[day] = hosts[day]!.id;
    }
  }
  if (hosts.default) {
    dailyIds.default = hosts.default.id;
  }
  return dailyIds;
};

export const areEquivalent = (
  a?: MeetingHostSchedule,
  b?: MeetingHostSchedule
) => {
  if (!a || !b) {
    return a === b;
  }
  return a.default === b.default && days.every((day) => a[day] === b[day]);
};
