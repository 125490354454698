import { Stack } from "@mui/material";
import React from "react";

import { RecurrenceDetailsView } from "./RecurringDetailsView";
import { InstanceDetailsView } from "./InstanceDetailsView";
import { MeetingResource } from "../../../../api/meetings";
import { InstanceMetadata } from "../../../../model/meeting_metadata";
import { Meeting } from "../../../../model/meeting";

interface MeetingDetailsContainerProps {
  selectedMeeting: Meeting;
}

function toInstanceMetadata(meeting: Meeting): InstanceMetadata {
  return {
    meetingType: meeting.metadata.meetingType,
    host: meeting.metadata.host,
    memberVisible: meeting.metadata.memberVisible ?? false,
  };
}

export const MeetingDetailsContainer: React.FC<
  MeetingDetailsContainerProps
> = ({ selectedMeeting }) => {
  const handleSaveInstanceMetadata = (updatedMeeting: Meeting) => {
    if (!updatedMeeting.id) return;
    const updatedMetadata = toInstanceMetadata(updatedMeeting);
    MeetingResource.update({ id: updatedMeeting.id }, updatedMetadata);
  };

  const isTrulyRecurring = selectedMeeting.recurringMeeting != undefined;

  return (
    <Stack
      gap={4}
      sx={{ padding: "20px", width: "100%", height: "100%", overflow: "auto" }}
    >
      {isTrulyRecurring && <RecurrenceDetailsView meeting={selectedMeeting} />}
      <InstanceDetailsView
        meeting={selectedMeeting}
        onSave={handleSaveInstanceMetadata}
      />
    </Stack>
  );
};
