import React from "react";
import {
  Box,
  List,
  ListItemButton,
  Divider,
  Stack,
  Typography,
} from "@mui/material";

import { BadgeType, DivergeInfo, MeetingInfoBadge } from "./MeetingInfoBadge";
import { Meeting, isMissingData } from "../../../model/meeting";
import { getDateString, getTimeString, hasApparentOverride } from "../helpers";

export interface MeetingListItemProps {
  meeting: Meeting;
}

const MeetingListItem: React.FC<MeetingListItemProps> = ({ meeting }) => {
  return (
    <Stack
      direction="row"
      gap={5}
      width="100%"
      alignItems="center"
      padding="20px"
    >
      <Stack flex={1}>
        <Typography
          variant="caption"
          style={{ color: "gray", marginLeft: "-5px" }}
        >
          {getDateString(meeting.start)}
        </Typography>
        <Stack
          direction="row"
          gap={0.75}
          alignItems="center"
          minWidth={300}
          justifyContent="flex-start"
        >
          <Typography variant="body1" fontWeight="bold">
            {meeting.title}
          </Typography>
          {hasApparentOverride(meeting) && <DivergeInfo />}
        </Stack>
        <Typography variant="body2" fontWeight="bold" style={{ color: "gray" }}>
          {getTimeString(meeting.start, meeting.end)}
        </Typography>
        <Typography variant="caption" style={{ color: "gray" }}>
          {meeting.host?.displayName ?? "Host unset"}
        </Typography>
        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          justifyContent="flex-start"
          marginTop="15px"
          marginLeft="-8px"
        >
          {meeting.metadata.meetingType && (
            <MeetingInfoBadge
              type={BadgeType.meetingType}
              meetingTypeLabel={meeting.metadata.meetingType}
            />
          )}
          {meeting.recurringMeeting && (
            <MeetingInfoBadge type={BadgeType.recurring} />
          )}
        </Stack>
      </Stack>
      <Stack gap={1} flex={1} alignItems="flex-end">
        {!meeting.metadata.memberVisible && (
          <MeetingInfoBadge type={BadgeType.notVisible} />
        )}
        {isMissingData(meeting) && (
          <MeetingInfoBadge type={BadgeType.missingData} />
        )}
      </Stack>
    </Stack>
  );
};

interface MeetingListProps {
  meetings: Meeting[];
  onSelectMeeting: (idx: number, mId: string) => void;
  selectedIdx?: number;
}

export const MeetingList: React.FC<MeetingListProps> = ({
  meetings,
  onSelectMeeting,
  selectedIdx,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 600,
        height: "100%",
        overflow: "auto",
      }}
    >
      <List>
        {meetings.map((md, idx) => {
          return (
            <React.Fragment key={idx}>
              <ListItemButton
                key={idx}
                onClick={() => onSelectMeeting(idx, md.id)}
                selected={selectedIdx === idx}
              >
                <MeetingListItem meeting={md} />
              </ListItemButton>
              <Divider />
            </React.Fragment>
          );
        })}
      </List>
    </Box>
  );
};
