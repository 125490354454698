import React from "react";
import { Logger } from "aws-amplify";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { AuthEventData, CognitoUserAmplify } from "@aws-amplify/ui";
import { Authenticator } from "@aws-amplify/ui-react";

const logger = new Logger("focused.space");

export interface AuthenticatedUserState {
  isAuthenticated: boolean;
  isUserLoading: boolean;
  authenticatedUser: any;
  currentSession?: CognitoUserSession;
  isHost: boolean;
  signOut: () => void;
}

const initialUserState: AuthenticatedUserState = {
  isAuthenticated: false,
  isUserLoading: true,
  authenticatedUser: undefined,
  currentSession: undefined,
  isHost: false,
  signOut: () => {},
};

const AuthenticatedUserStateContext =
  React.createContext<AuthenticatedUserState>(initialUserState);

interface InternalProviderProps {
  signOut: ((data?: AuthEventData | undefined) => void) | undefined;
  user?: CognitoUserAmplify;
}

const InternalProvider: React.FC<
  React.PropsWithChildren<InternalProviderProps>
> = ({ signOut, user, children }) => {
  const [userSession, setUserSession] = React.useState<CognitoUserSession>();

  const setUserState = async () => {
    if (user) {
      user.getSession(
        (err: Error | null, session: CognitoUserSession | null) => {
          if (session) {
            setUserSession(session);
          } else {
            throw new Error(`unable to retrieve user session, ${err}`);
          }
        }
      );
    }
  };

  React.useEffect(() => {
    if (!user) {
      console.info("user is undefined");
      return;
    }

    if (!signOut) {
      console.info("signOut is undefined");
      return;
    }

    setUserState();
  }, []);

  const userState = React.useCallback(() => {
    if (!user || !userSession) {
      return initialUserState;
    }

    const token = userSession.getAccessToken();
    if (token) {
      const userGroups = token.payload["cognito:groups"] as Array<string>;
      const foundHostGroup = userGroups && userGroups.includes("Hosts");

      return {
        isAuthenticated: true,
        isUserLoading: false,
        authenticatedUser: user,
        currentSession: userSession,
        isHost: foundHostGroup,
        signOut: () => {
          if (signOut) {
            signOut();
          }
        },
      };
    } else {
      console.log("no token?");
      return initialUserState;
    }
  }, [userSession]);

  return (
    <AuthenticatedUserStateContext.Provider value={userState()}>
      {children}
    </AuthenticatedUserStateContext.Provider>
  );
};

export const AuthenticatedUserProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <Authenticator>
      {({ signOut, user }) => (
        <InternalProvider signOut={signOut} user={user}>
          {children}
        </InternalProvider>
      )}
    </Authenticator>
  );
};

export const useAuthenticatedUserState = () => {
  const context = React.useContext(AuthenticatedUserStateContext);

  if (!context) {
    throw new Error(
      "useAuthenticatedUserState must be used within AuthenticatedUserStateProvider"
    );
  }

  return context;
};
