import React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import { useFormik } from "formik";
import * as yup from "yup";
import { UserResource } from "../../api/users";

interface AddUserFields {
  email: string;
  nickname: string;
  stripeCustomerId?: string;
}

interface AddUserFormProperties {
  onClose: () => void;
}

export const AddUserForm: React.FC<AddUserFormProperties> = ({ onClose }) => {
  const [errorMessage, setErrorMessage] = React.useState<string>();

  const initialValues: AddUserFields = {
    email: "",
    nickname: "",
    stripeCustomerId: "",
  };

  const validationSchema = yup.object({
    email: yup.string().email().required("A valid email is required."),
    nickname: yup.string().required(),
    stripeCustomerId: yup
      .string()
      .optional()
      .matches(/^cus_/, 'Stripe customer ID must start with "cus_"'),
  });

  validationSchema.when("stripeCustomerId", {
    is: (val?: string) => val && val.length > 0,
    then: yup
      .string()
      .required()
      .matches(/^cus_/, 'Stripe customer ID must start with "cus_"'),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, helpers) => {
      const result = await UserResource.createUser({
        email: values.email.trim(),
        fullName: values.nickname.trim(),
        ...(values.stripeCustomerId && {
          stripeCustomerId: values.stripeCustomerId.trim(),
        }),
      });
      if (!result.errors) {
        formik.setValues(initialValues);
        onClose();
      } else {
        setErrorMessage(`Could not create user! Error: ${result.errors}`);
      }
    },
  });

  return (
    <Card
      style={{
        padding: "1rem",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Typography variant="h4" style={{ flex: 1, paddingBottom: "1rem" }}>
          Create a New Focused User
        </Typography>
        <IconButton onClick={onClose}>
          <CloseOutlinedIcon />
        </IconButton>
      </Box>
      <Box>
        {errorMessage && <Typography variant="h6">{errorMessage}</Typography>}
      </Box>
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="nickname"
                name="nickname"
                label="Nickname"
                value={formik.values.nickname}
                onChange={formik.handleChange}
                error={
                  formik.touched.nickname && Boolean(formik.errors.nickname)
                }
                helperText={formik.touched.nickname && formik.errors.nickname}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="stripeCustomerId"
                name="stripeCustomerId"
                label="Stripe Customer ID (optional)"
                value={formik.values.stripeCustomerId}
                onChange={formik.handleChange}
                error={
                  formik.touched.stripeCustomerId &&
                  Boolean(formik.errors.stripeCustomerId)
                }
                helperText={
                  formik.touched.stripeCustomerId &&
                  formik.errors.stripeCustomerId
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              >
                Save & Send Invite
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Card>
  );
};

export const AddUser = () => {
  const [formVisible, setFormVisible] = React.useState(false);

  return (
    <Box>
      <IconButton
        onClick={(e) => {
          e.preventDefault();
          setFormVisible(true);
        }}
      >
        <AddCircleOutlineIcon />
      </IconButton>
      <Dialog open={formVisible}>
        <AddUserForm onClose={() => setFormVisible(false)} />
      </Dialog>
    </Box>
  );
};
