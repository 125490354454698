import format from "date-fns/format";
import { RRule } from "rrule";
import { Host } from "../../api/hosts";
import { Meeting } from "../../model/meeting";
import { areEquivalent } from "../../model/meeting_host_schedule";

/// descriptionToString removes any html tags from the description
export const descriptionToString = (description?: string) => {
  /// matches any html tag
  const htmlTagRegex = new RegExp(/<[^>]*>/g);

  return description?.replace(htmlTagRegex, "") || "";
};

export const getDateString = (start: Date) => {
  const formatted = format(start, "EEEE M/dd/yy");
  const [dayOfWeek, date] = formatted.split(" ");
  return `${dayOfWeek}, ${date}`;
};

export const getTimeString = (start?: Date, end?: Date) => {
  if (!start || !end) return undefined;

  return new Intl.DateTimeFormat("en-US", {
    timeZoneName: "short",
    hour: "numeric",
    minute: "numeric",
  }).formatRange(start, end);
};

export const recurrenceRuleString = (recurrenceRule?: string[] | null) => {
  if (!recurrenceRule) return undefined;
  return recurrenceRule.map((rule) => {
    const rrule = RRule.fromString(rule);
    const text = rrule.toText() as string;
    return text.toUpperCase();
  });
};

export const meetingTypeMap: Record<string, string> = {
  community_session: "Community Session",
  custom: "Custom",
  monthly_planning: "Monthly Planning",
  new_member_orientation: "New Member Orientation",
  night_owl: "Night Owl Sessions",
  office_hours: "Office Hours",
  power_hour: "Power Hour",
  wakeup_call: "Wakeup Call",
  weekly_planning: "Weekly Planning",
};
export enum MeetingTypes {
  community_session = "Community Session",
  custom = "Custom",
  monthly_planning = "Monthly Planning",
  new_member_orientation = "New Member Orientation",
  night_owl = "Night Owl Sessions",
  office_hours = "Office Hours",
  power_hour = "Power Hour",
  wakeup_call = "Wakeup Call",
  weekly_planning = "Weekly Planning",
}

export const meetingTypeToString = (meetingType: string) =>
  meetingTypeMap[meetingType];

export const meetingStringToType = (meetingString: string) =>
  Object.keys(meetingTypeMap).find(
    (key) => meetingTypeMap[key] === meetingString
  );

export function hasApparentOverride(meeting: Meeting): boolean {
  const instance = meeting.metadata;
  const recurring = meeting.recurringMeeting?.metadata;
  return (
    instance.host !== undefined ||
    instance.meetingType !== recurring?.meetingType ||
    instance.memberVisible !== recurring?.memberVisible
  );
}

export const meetingHostFromString = (
  hostsString: string,
  meetingDay: string,
  hostsById: Record<string, Host>
): Host | undefined => {
  try {
    const hostId = JSON.parse(hostsString);
    if (hostId && typeof hostId === "object" && hostId.default) {
      return hostsById[
        meetingDay in hostId ? hostId[meetingDay] : hostId.default
      ];
    }
  } catch (e) {}
  return hostsById[hostsString];
};
