import React from 'react';
import { Home } from './pages/Home';
import { NonHost } from './pages/NonHost';
import { useAuthenticatedUserState } from './providers/AuthenticatedUserProvider';

import '@aws-amplify/ui-react/styles.css';

export const Main = () => {
    const { isHost } = useAuthenticatedUserState();

    if (!isHost) {
        return <NonHost />
    }

    return <Home />;
}