import ReplayIcon from "@mui/icons-material/Replay";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ErrorIcon from "@mui/icons-material/Error";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

import { Color } from "../../../styles/SharedStyles";
import { Box, Stack, Tooltip, Typography } from "@mui/material";

export enum BadgeType {
  "missingData",
  "notVisible",
  "recurring",
  "meetingType",
}

export const MeetingInfoBadge = ({
  type,
  meetingTypeLabel,
}: {
  type: BadgeType;
  meetingTypeLabel?: string;
}) => {
  let badgeColor;
  let textColor;
  let label;
  let icon;
  let style = { fontSize: "16px", color: "" };

  switch (type) {
    case BadgeType.missingData:
      badgeColor = "#FFD9E4";
      textColor = "red";
      label = "Missing data";
      icon = <ErrorIcon style={{ ...style, color: textColor }} />;
      break;
    case BadgeType.notVisible:
      badgeColor = "#F5F5F5";
      textColor = "gray";
      label = "Not visible";
      icon = <VisibilityOffIcon style={{ ...style, color: textColor }} />;
      break;
    case BadgeType.recurring:
      badgeColor = Color.LightestPurple;
      label = "Recurring";
      icon = <ReplayIcon style={{ ...style, color: "gray" }} />;
      break;
    case BadgeType.meetingType:
      badgeColor = "#F5F5F5";
      label = meetingTypeLabel;
      break;
  }

  return (
    <Box
      sx={{
        borderRadius: "10px",
        backgroundColor: badgeColor,
        padding: "6px 10px",
        width: "fit-content",
        height: "28px",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1.5}
      >
        {icon}
        <Typography
          style={{
            color: textColor,
            fontSize: "10px",
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          {label}
        </Typography>
      </Stack>
    </Box>
  );
};

export const DivergeInfo = () => {
  return (
    <Tooltip title="This instance differs from the recurring meeting">
      <InfoOutlined style={{ fontSize: "16px", color: "gray" }} />
    </Tooltip>
  );
};
