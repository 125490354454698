import { InstanceMetadata, RecurringMetadata } from "./meeting_metadata";
import { Meeting as ApiMeeting } from "../api/meetings";
import {
  instanceFromApiMeeting as instanceFromApiMeetingMetadata,
  recurringFromApiMeeting as recurringFromApiMeetingMetadata,
} from "./meeting_metadata";
import { parseISO } from "date-fns";
import { Host } from "./host";

export interface RecurringMeeting {
  id: string;
  recurrenceRule: string[];
  metadata: RecurringMetadata;
}

export interface Meeting {
  id: string;
  title: string;
  start: Date;
  end: Date;
  description: string;
  meetingUrl: string;
  host?: Host;
  metadata: InstanceMetadata;
  recurringMeeting?: RecurringMeeting;
}

export const fromApiMeeting = (
  apiMeeting: ApiMeeting,
  hostsById: Record<string, Host>
): Meeting => {
  const recurringMeeting: RecurringMeeting | undefined =
    apiMeeting.recurringMeeting
      ? {
          id: apiMeeting.recurringMeeting.id,
          recurrenceRule: apiMeeting.recurringMeeting.recurrenceRule ?? [],
          metadata: recurringFromApiMeetingMetadata(apiMeeting, hostsById) ?? {
            memberVisible: false,
          },
        }
      : undefined;
  return {
    id: apiMeeting.id,
    title: apiMeeting.title,
    start: parseISO(apiMeeting.start),
    end: parseISO(apiMeeting.end),
    description: apiMeeting.description,
    meetingUrl: apiMeeting.meetingUrl,
    host: apiMeeting.hostId ? hostsById[apiMeeting.hostId] : undefined,
    metadata: instanceFromApiMeetingMetadata(apiMeeting, hostsById),
    recurringMeeting,
  };
};

export const isMissingData = (meeting: Meeting): boolean =>
  !meeting.metadata.meetingType && !meeting.host;
