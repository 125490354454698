import { Button, Stack, Typography } from "@mui/material";
import { BadgeType, MeetingInfoBadge } from "../MeetingInfoBadge";
import { DetailField } from "./DetailField";
import React from "react";
import { InstanceMetadataEditDialog } from "../metadata_editing/InstanceMetadataEditDialog";
import {
  descriptionToString,
  getDateString,
  getTimeString,
  hasApparentOverride,
  meetingTypeToString,
} from "../../helpers";
import { Meeting } from "../../../../model/meeting";

interface InstanceDetailsViewProps {
  meeting: Meeting;
  onSave: (toSave: Meeting) => void;
}

export const toInstanceDetail = (meeting: Meeting) => {
  const { recurringMeeting } = meeting;

  const meetingType = meeting.metadata.meetingType
    ? meetingTypeToString(meeting.metadata.meetingType)
    : null;

  const host = meeting.host;
  const missingData = !meetingType || !host;
  const visible = meeting.metadata.memberVisible;
  const differentFromRecurring = recurringMeeting
    ? hasApparentOverride(meeting)
    : undefined;

  return {
    title: meeting.title,
    meetingType: meetingType,
    date: getDateString(meeting.start),
    time: getTimeString(meeting.start, meeting.end),
    description: descriptionToString(meeting.description),
    isRecurring: recurringMeeting ? true : false,
    hostName: host?.displayName,
    isVisible: visible ?? false,
    isMissingData: missingData,
    isDifferentFromRecurring: differentFromRecurring,
  };
};

export const InstanceDetailsView: React.FC<InstanceDetailsViewProps> = ({
  meeting,
}) => {
  const [isEditing, setIsEditing] = React.useState(false);

  const {
    title,
    date,
    time,
    meetingType,
    hostName,
    isMissingData,
    isVisible,
    description,
  } = toInstanceDetail(meeting);

  return (
    <Stack gap={2} padding="20px 60px">
      <Stack
        gap={1}
        flex={1}
        alignItems="flex-end"
        direction="row"
        marginLeft="-20px"
      >
        {!isVisible && <MeetingInfoBadge type={BadgeType.notVisible} />}
        {isMissingData && <MeetingInfoBadge type={BadgeType.missingData} />}
      </Stack>

      <Stack gap={2} direction="row" alignItems="baseline">
        <Typography variant="h4" fontWeight="bold">
          {title}
        </Typography>
        <Button
          variant="text"
          onClick={() => setIsEditing(!isEditing)}
          sx={{ marginTop: "10px" }}
        >
          Edit Instance
        </Button>
      </Stack>
      <DetailField key={meeting.id} field="id" value={meeting.id} />
      <DetailField key={date} field="date" value={date} />
      <DetailField key={time} field="time" value={time} />
      <DetailField key={hostName} field="hostName" value={hostName} />
      <DetailField key={meetingType} field="meetingType" value={meetingType} />
      <DetailField
        key={"description"}
        field="description"
        value={description}
      />
      <InstanceMetadataEditDialog
        key={meeting.id}
        meeting={meeting}
        open={isEditing}
        onCancel={() => setIsEditing(false)}
      />
    </Stack>
  );
};
