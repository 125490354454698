import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
} from "@mui/material";
import { RecurringHostSelection } from "../host_selection/Recurring";
import { Meeting } from "../../../../model/meeting";
import { MeetingHostSchedule } from "../../../../model/meeting_host_schedule";
import { useBoundStore } from "../../../../store/store";

interface Props {
  meeting: Meeting;
  open: boolean;
  onCancel: () => void;
}

export const RecurringMetadataEditDialog: React.FC<Props> = ({
  meeting: originalMeeting,
  open,
  onCancel,
}) => {
  if (!originalMeeting.recurringMeeting) {
    throw new Error("Meeting is not recurring");
  }

  const setMeetingMetadata = useBoundStore(
    (state) => state.setRecurringMetadata
  );
  const [currentMeeting, setCurrentMeeting] = useState(
    originalMeeting.recurringMeeting!
  );

  const handleSave = React.useCallback(async () => {
    const result = await setMeetingMetadata(
      currentMeeting.id,
      currentMeeting.metadata
    );
    onCancel();
  }, [currentMeeting]);

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onCancel}>
      <DialogTitle>
        <Stack gap={0.5}>
          {"Editing "}
          {originalMeeting.title}{" "}
        </Stack>
      </DialogTitle>

      <DialogContent>
        <RecurringHostSelection
          value={currentMeeting?.metadata?.hostSchedule ?? {}}
          onChange={(value: MeetingHostSchedule) =>
            setCurrentMeeting({
              ...currentMeeting!,
              metadata: {
                ...currentMeeting!.metadata,
                hostSchedule: value,
              },
            })
          }
        />

        <FormControl fullWidth margin="normal">
          <InputLabel>Meeting Type</InputLabel>
          <Select
            label="Meeting Type"
            value={currentMeeting.metadata.meetingType}
            onChange={(e) =>
              setCurrentMeeting({
                ...currentMeeting,
                metadata: {
                  ...currentMeeting.metadata,
                  meetingType: e.target.value as string,
                },
              })
            }
          >
            {/* Add options for meeting types */}
            <MenuItem value="community_session">Community Session</MenuItem>
            <MenuItem value="custom">Custom</MenuItem>
            <MenuItem value="new_member_orientation">
              New Member Orientation
            </MenuItem>
            <MenuItem value="night_owl">Night Owl</MenuItem>
            <MenuItem value="power_hour">Power Hour</MenuItem>
            <MenuItem value="wakeup_call">Wakeup Call</MenuItem>
            <MenuItem value="weekly_planning">Weekly Planning</MenuItem>
            <MenuItem value="monthly_planning">Monthly Planning</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <FormControlLabel
            control={
              <Switch
                checked={currentMeeting.metadata.memberVisible}
                onChange={() =>
                  setCurrentMeeting({
                    ...currentMeeting,
                    metadata: {
                      ...currentMeeting.metadata,
                      memberVisible: !currentMeeting.metadata.memberVisible,
                    },
                  })
                }
              />
            }
            label="Visible to Members"
          />
          <FormHelperText>
            When checked, members are able to see and subscribe to this meeting
          </FormHelperText>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
