import React from "react";

import Box from "@mui/material/Box";

import { AddUser } from "./AddUser";
import { UserList } from "./UserList";

export const Management = () => {
  return (
    <Box>
      <AddUser />
      <UserList />
    </Box>
  );
};
